<script>
  import {STATUS_MAPPER_MAP} from "@/utils/config";

  export default {
    name: 'StateShow',
    props: {
      text: {
        type: String,
        default() {
          return ''
        }
      },
      status: {
        type: String,
        default() {
          return ''
        }
      },
      custom: {
        type: Boolean,
        default() {
          return false
        }
      }
    },
    computed: {
      getStatusClass(){
        if (this.custom) {
          return this.status
        }
        return STATUS_MAPPER_MAP.get(`${this.status}-STATUS`)
      }
    },
  }
</script>

<template>
  <div class="state-show">
    <div class="prefix" :class="getStatusClass"></div>
    <div>{{ text }}</div>
  </div>
</template>

<style scoped lang="scss">
.state-show {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .prefix{
    margin-right: 4px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
  // 初始
  .INIT {
    background-color: #0D6CE4;
  }
  // 进行中
  .ING {
    background-color: #FA9600;
  }
  // 拒绝
  .REFUSE {
    background-color: #FC474C;
  }
  // 完成
  .COMPLETE {
    background-color: #19B21E;
  }
  // 其他
  .OTHER {
    background-color: #B4B9BF;
  }
}
</style>